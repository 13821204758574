<template>
  <b-list-group-item>
    <div class="text-primary font-weight-bolder mb-0">
      {{ itemTitle }}
    </div>
    <div v-if="itemData">
      <b-row
        v-for="(item,key) in itemData"
        :key="key"
        class="mb-2 pt-1 pb-2 border-bottom"
      >
        <b-col cols="auto">
          <b-avatar
            :text="item.avatar_text"
            variant="primary"
            size="48"
          />
        </b-col>
        <b-col
          xs="12"
          md="3"
        >
          <div class="font-weight-bolder">
            {{ $t('Adı Soyadı') }}
          </div>
          <div>{{ item.name }}</div>
        </b-col>
        <b-col>
          <div class="font-weight-bolder">
            {{ $t('Görevi') }}
          </div>
          <div>{{ item.title }}</div>
        </b-col>
      </b-row>
    </div>
  </b-list-group-item>
</template>

<script>
import {
  BListGroupItem, BRow, BCol, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'TeamItems',
  components: {
    BListGroupItem,
    BAvatar,
    BRow,
    BCol,
  },
  props: {
    itemTitle: {
      type: String,
      required: true,
    },
    itemData: {
      type: Array,
      required: false,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
