<template>
  <b-list-group-item>
    <div class="text-primary font-weight-bolder mb-0">
      {{ itemTitle }}
    </div>
    <div
      v-if="itemSubTitle"
      class="text-muted"
    >
      <small>{{ itemSubTitle }}</small>
    </div>
    <div v-if="itemValue">
      {{ itemPrepend }} {{ itemValue }} {{ itemAddon }}
    </div>
    <div v-if="itemData">
      <div
        v-for="(item,key) in itemData"
        :key="key"
      >
        {{ item }}
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ListItems',
  components: {
    BListGroupItem,
  },
  props: {
    itemTitle: {
      type: String,
      required: true,
    },
    itemSubTitle: {
      type: String,
      required: false,
      default: null,
    },
    itemValue: {
      type: String,
      required: false,
      default: null,
    },
    itemData: {
      type: Array,
      required: false,
      default: null,
    },
    itemPrepend: {
      type: String,
      required: false,
      default: null,
    },
    itemAddon: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
